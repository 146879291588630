@import '../../assets/scss/responsive';

.teamModeBanner {
    padding: 32px 56px;
    align-items: center;
    h1 {
      font-size: 24px;
      line-height: 34px;
      text-transform: uppercase;
      font-weight: 700;
      margin: 0;
      padding: 0;
    }
    p {
      margin: 12px 0 0 0;
      opacity: 0.9;
      line-height: 24px;
    }
    @include breakpoint(sm) {
      padding: 16px 24px;
      img {
        max-height: 154px;
      }
      h1 {
        font-size: 18px;
        line-height: 25px;
      }
    }
  }
  