@import "./colors";

.header {
  display: flex;
  justify-content: space-between;
  background: $primary-color-3;
  align-items: center;
  height: 80px;
  position: fixed;
  left: 0;
  width: 100%;
  z-index: 99;
  h6 {
    font-size: 18px;
    line-height: 26px;
    font-weight: 700;
    margin: 0;
    padding: 0;
  }
  .main-container {
    width: 100%;
    justify-content: space-between;
    display: flex;
  }

  &__logo {
    display: flex;
    align-items: center;
    max-width: 200px;
    
    @include breakpoint(sm) {
      max-width: 150px;
    }

    img {
      width: 36px;
      height: 36px;
      margin-right: 8px;
    }

    span {
      text-transform: uppercase;
      color: white;
      display: inline-flex;
      flex-direction: column;
      font-size: 14px;
      font-weight: 600;

      strong {
        font-weight: 600;
        max-width: 150px;
      }
    }
  }

  &__links {
    display: flex;

    ul {
      margin: 0 0 0 54px;
      padding: 0;
      list-style: none;

      li {
        display: inline-flex;

        a {
          opacity: 0.6;
          padding: 26px 16px;
          border-bottom: 4px solid transparent;
          text-decoration: none;
          color: $white;
          font-size: 18px;
          margin-right: 32px;
          text-transform: uppercase;
          transition: 0.3s all;

          &.active {
            opacity: 1;
            border-color: white;
          }

          &:hover {
            opacity: 1;
          }
        }
      }
    }
  }

  &__actions {
    display: flex;

    &--btn {
      display: inline-flex;
      align-items: center;

      button {
        margin: 0;
        padding: 0;
        outline: none !important;

        .MuiAvatar-circular {
          font-family: inherit;
          font-size: 15px;
          font-weight: 700;
          color: $body-text;
          background: $secondary-color-3;
          border: 2px solid $white;

          @include breakpoint(sm) {
            font-size: 12px;
          }
        }
      }

      svg {
        color: $white;

        &:hover {
          opacity: 0.8;
        }
      }

      &.file-icon {
        margin: 0 26px 0 40px;

        svg {
          width: 20px;
          cursor: pointer;
        }
      }

      &.more-icon {
        svg {
          height: 32px;
        }
      }

      &.user-icons {
        button {
          z-index: 2;

          &.multi-user-btn {
            z-index: 1;
            left: -12px;
          }
        }
      }
    }
  }

  &[area-label="French"] {
    &+.main-wrapper {
      .new-count-head .secondary-btn {
        font-size: 13px;
      }

      .new-count-title {
        font-size: 14px;
      }
    }
  }
}

.profile-dropdown {
  .MuiMenu-paper {
    margin-top: 17px;

    &::before {
      display: none;
    }
  }

  ul {
    margin: 0 !important;
    padding: 0;
    max-width: 268px;
    min-width: 268px;
  }

  &__name {
    background: $primary-color-3;
    color: $white;
    padding: 24px 24px 16px 24px;
    text-transform: capitalize;
    width: 100%;
    cursor: default;

    &:hover {
      background: $primary-color-3 !important;
    }

    .user-role {
      display: block;
      opacity: 0.8;
      font-size: 12px;
      text-transform: uppercase;
    }
  }

  &__address {
    padding: 24px;
    font-size: 12px;

    strong {
      display: block;
      font-size: 14px;
      line-height: 22px;
      margin-bottom: 4px;
    }

    &:hover {
      background: inherit !important;
    }
  }

  &__logout {
    margin: 0px 24px 24px 24px;
    border-top: 1px solid rgba($secondary-color-4, 0.3);

    &:hover {
      background: inherit !important;
    }
  }
}

.main-wrapper {
  margin-top: 160px !important;
  overflow: auto;
  height: calc(100vh - 342px);
  min-height: calc(100vh - 342px);
  // removed padding to maintain the container padding
  // padding-right: 8px;
  padding-bottom: 20px;

  @include breakpoint(sm) {
    // shifiting the main container 
    margin-top: 120px !important;
    // setting the height to remove the white space b/w container and footer
    height: calc(100vh - 230px);
    min-height: calc(100vh - 230px);
  }

  &.is-inventory {
    // shifiting the main container 
    margin-top: 270px !important;
    height: calc(100vh - 450px);
    min-height: calc(100vh - 450px);

    @include breakpoint(sm) {
      // shifiting the main container 
      margin-top: 218px !important;
      // setting the height to remove the white space b/w container and footer
      height: calc(100vh - 345px);
      min-height: calc(100vh - 345px);
    }

    [class*="ToolBarHeader_Container__"] {
      top: 187px;
    }
  }
}

.no-footer {
  .main-wrapper {
    height: 100%;
    min-height: 100%;
    margin-top: 60px !important;
    padding-bottom: 0;
  }
}

.lang-dropdown {
  font-family: "Open Sans", sans-serif;
  margin-right: 24px;

  .MuiSelect-select {
    position: relative;
    padding: 0 30px 20px 0 !important;
    border: none !important;
    outline: none;
    top: 10px;

    &:after {
      content: "";
      position: absolute;
      right: 3px;
      border: solid white;
      border-width: 0 2px 2px 0;
      display: inline-block;
      padding: 3px;
      top: 10px;
      transform: rotate(45deg);
      pointer-events: none;
    }
  }

  fieldset {
    border: none;
    padding: 0;
  }

  .MuiSelect-icon {
    display: none;
  }

  &__list small,
  small {
    position: relative;
    // padding-left: 42px;
    padding-left: 24px;
    color: white;
    font-size: 13px;
    font-weight: 700;
    display: flex;
    align-items: center;
    min-height: 32px;

    &:before {
      content: "";
      width: 32px;
      height: 32px;
      position: absolute;
      left: 0;
      top: 0;
      border-radius: 50px;
      background-repeat: no-repeat;
      background-size: cover;
    }

    &.English {
      &::before {
        background-image: url(../images/EnglishFlag.svg);
      }
    }

    &.French {
      &::before {
        background-image: url(../images/CanadaFlag.png);
      }
    }
    &.Spanish {
      &::before {
        background-image: url(../images/SpanishFlag.png);
        background-position: center;
      }
    }
  }

  @include breakpoint(vl) {
    // display: none;
    .lang-dropdown small,
    small {
      padding-left: 42px;    
    }
  }
}

.MuiMenu-paper ul {
  .dropdown-list.lang-dropdown__list {
    // min-width: 240px;
    min-width: 84px;
    background: #f5f5f5;

    small {
      margin: 0;
      padding: 13px 13px 13px 48px;
      font-size: 15px;

      &:before {
        width: 24px;
        height: 24px;
        left: 13px;
        top: 12px;
      }
    }

    &.Mui-selected {
      background: white;
    }

    &[aria-selected="true"]:after {
      content: "";
      position: absolute;
      right: 24px;
      top: 16px;
      width: 8px;
      height: 15px;
      border-width: 0 2px 2px 0;
      border-style: solid;
      transform: rotate(45deg);
      color: #008800;
    }

    &:last-child {
      margin-bottom: -72px;
      padding-bottom: 20px;

      small {
        border-bottom: none;
      }
    }
    @include breakpoint(vl) {
      min-width: 240px; /* Adjusted min-width for 'vl' breakpoint */
    }
  }
}

.note-text {
  font-size: 12px;
  text-align: right;
  display: block;
  margin-top: 3px;
  line-height: 16px;
  color: #666;
}

.error-text {
  color: #d32f2f;
}

.password-input {
  input {
    -webkit-text-security: disc;
    -moz-text-security: disc;
    -ms-text-security: disc;
  }
}

.text-decoration-none {
  text-decoration: none;
}

.multi-user {
  margin-top: 13px;

  &__col {
    display: flex;
    align-items: center;
    padding: 5px 12px;
    min-width: 280px;
    cursor: pointer;

    label {
      font-size: 16px;
      font-weight: 600;
      margin-left: 12px;
      cursor: pointer;
    }

    .MuiAvatar-circular {
      width: 40px;
      height: 40px;
      font-size: 12px;
      font-weight: 700;
      color: $body-text;
      background: $secondary-color-3;
      border: 2px solid rgba($black, 0.1);
    }

    &:hover {
      background: $secondary-color-1 !important;
    }
  }

  .other-users {
    font-weight: 700;
    padding: 12px;
    pointer-events: none;
  }
}

// Responsive Code
@include breakpoint(sm) {
  .header {
    height: 48px;

    &__logo {
      img {
        width: 28px;
        height: 28px;
      }
    }

    &__links {
      ul {
        margin-left: 48px;

        li {
          a {
            margin-right: 32px;
            font-size: 14px;
            padding: 12px 0;
          }
        }
      }
    }

    &__actions {
      &--btn {
        button {
          .MuiAvatar-circular {
            height: 32px;
            width: 32px;
          }
        }

        &.file-icon {
          margin: 0 16px 0 24px;

          svg {
            width: 16px;
          }
        }

        &.more-icon {
          svg {
            height: 24px;
          }
        }
      }
    }
  }

  [class*="ToolBarHeader_Container__"] {
    // reduce the position for sub header
    top: 50px !important;

    .custom-search {
      height: 36px;

      input {
        height: 36px;
      }
    }

    button {
      height: 36px;
      width: 36px;
      min-height: 36px;

      svg {
        font-size: 16px;
      }
      &.cancel-btn {
        width: auto;
      }
    }
  }
}


.field-text-danger {
  color: #d32f2f
}