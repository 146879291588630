@import "./colors";

.blue-bg {
  background: $secondary-color-3 !important;
}

.gray-bg {
  background: $secondary-color-1 !important;
}

.white-bg {
  background: $white !important;
}

.custom-card {
  background: $secondary-color-3;
  display: flex;
  align-items: center;

  h4 {
    font-size: 24px;
    line-height: 34px;
    font-weight: 700;
    margin-bottom: 12px;
  }

  p {
    opacity: 0.9;
  }

  &__img {
    text-align: right;

    img {
      max-height: 150px;
    }
  }
}

.tag-list {
  background: $secondary-color-1;
  padding: 10px 32px 10px 32px;
  // padding: 10px;
  align-items: center;
  justify-content: space-between;

  &__icon {
    display: inline-flex;
    margin-right: 32px;
    position: relative;
    top: 12px;
    @include breakpoint(sm) {
      margin-right: 16px;
    }
  }

  &__col {
    display: inline-flex;
    margin-right: 54px;
    flex-direction: column;
    font-size: 15px;
    line-height: 22px;
    text-transform: uppercase;
    &-edit {
      font-size: 16px;
      margin-left: 4px;
      top: -2px;
      position: relative;
    }
    @include breakpoint(sm) {
      margin-right: 14px;
    }

    &-title {
      line-height: 24px;
      color: #727272;
      font-weight: 600;
    }

    &-value {
      display: block;
      margin-top: 4px;
      font-weight: 700 !important;

      &.ellipsis {
        max-width: 100%;
      }
    }

    &.name {
      max-width: 150px;
    }

    &.last-update {
      max-width: 170px;
    }

    &.desc {
      max-width: 55%;
      margin-right: 0;
    }

    &.range-name {
      max-width: 220px;
    }

    &.variance-name {
      width: 240px;
    }

    &.location-label {
      max-width: 220px;

      .ellipsis {
        max-width: 100%;
      }
    }

    &.ext {
      max-width: 100px;

      @include breakpoint(sm) {
        max-width: 80px;
      }
    }

    &.counted-by {
      max-width: 100px;
    }

    .counted-by {
      @include breakpoint(sm) {
        max-width: 100px;
      }
    }

    .mt1 {
      margin-top: 4px;
    }
  }

  &__count {
    width: 50%;
    text-align: right;
    display: flex;
    justify-content: right;
    align-items: center;
  }

  &__tags-count {
    display: flex;
    justify-content: right;
    align-items: center;
  }

  @include breakpoint(sm) {
    padding: 16px;

    &__col {
      &-title {
        line-height: 16px;
        opacity: 0.7;
        font-size: 12px;
        font-weight: 700;
      }

      &-value {
        font-size: 12px;
        line-height: 24px;
      }

      &.custom-progress {
        margin: 0 0 8px 0;
      }
    }

    &__count {
      align-items: end;
      flex-direction: column-reverse;
      width: auto;
    }
  }

  &.type-2 {
    .MuiGrid-root {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }

    .tag-list {
      &__col {
        margin-right: 0;
        @include breakpoint(md) {
          font-size: 13px;
        }
        @include breakpoint(xl) {
          font-size: 16px;
        }

        &.icon {
          max-width: 28px;
          justify-content: center;

          img {
            width: 100%;
          }
        }

        .right-text {
          text-align: right;
        }

        &.cb {
          @include breakpoint(md) {
            max-width: 110px;
          }
        }

        &.actions {
          flex-direction: row;
          //width: 380px;
          padding-right: 0;

          .primary-btn {
            height: auto;
            padding: 8px 12px;

            svg {
              fill: $white;
              stroke: $white;
              stroke-width: 2px;
            }
          }

          .primary-btn1 {
            padding: 8px 12px;
            align-self: baseline;
            height: 56px;
            svg {
              fill: $white;
              stroke: $white;
              stroke-width: 2px;
            }
          }

          @include breakpoint(vs) {
            .primary-btn1 {
              height: auto;
            }
          }

          button {
            svg {
              font-size: 24px !important;
            }
          }

          @include breakpoint(vs) {
            width: 100%;
            justify-content: center;
            margin-top: 24px;
            border-top: 1px solid $secondary-color-2;
            padding-top: 16px;
          }

          @include breakpoint(xl) {
            width: 530px;
            justify-content: right;
          }

          @include breakpoint(xxl) {
            width: 640px;
          }
        }
      }
    }
  }

  &.tag-details-list {
    @include breakpoint(vs) {
      padding-top: 0;

      .tag-details-list__col {
        flex-wrap: wrap;
        width: calc(100% - 150px);
        justify-content: space-around;

        .tag-list__col {
          min-width: 23%;
          margin-top: 20px;
          
        }
      }
    }

    .tag-list__col {
      @include breakpoint(md) {
        margin-right: 55px;
      }

      @include breakpoint(lg) {
        margin-right: 60px;
      }

      @include breakpoint(vl) {
        margin-right: 75px;
      }

      @include breakpoint(xl) {
        margin-right: 95px;
      }

      @include breakpoint(xxl) {
        margin-right: 120px;
      }
    }
  }
}

//Reports cards
.reports-card {
  &--link,
  &-info {
    cursor: pointer;
    display: flex;
    padding: 32px 32px 8px 80px;
    text-decoration: none;
    color: $body-text;
    flex-direction: column;
    position: relative;
    min-height: 192px;
    background: $secondary-color-1;

    h6 {
      font-size: 22px;
      line-height: 30px;
      font-weight: 600;
    }

    p {
      font-size: 15px;
      line-height: 20px;
      margin-bottom: 0;
      min-height: 60px;
    }

    a {
      color: inherit;

      &:hover {
        color: $body-text;
      }
    }

    &:hover {
      color: $body-text;
      opacity: 0.9;
    }
  }

  &__icon {
    font-size: 32px;
    position: absolute;
    left: 32px;
    top: 40px;
    width: 32px;
  }

  &-footer {
    border-top: 1px dashed rgba($secondary-color-2, 0.6);
    margin-left: -48px;
    padding-top: 8px;
    margin-top: 16px;

    .icon-btn {
      padding: 0;
      margin: 0 16px 0 0px;
      background: transparent !important;
      width: auto;
      position: relative;
      z-index: 9;

      img {
        width: 26px;
      }

      span {
        display: none;
      }

      &:hover {
        opacity: 0.8;
      }
    }

    a {
      color: $body-text;

      &:hover {
        color: $body-text;
        opacity: 0.8;
      }
    }
    &.sign-actions {
      width: auto;
      .primary-btn {
        text-transform: inherit;
      }
      .sign-text {
        font-weight: 600;
        cursor: default;
        img {
          margin-right: 8px;
        }
        &.red-text {
          img {
            filter: invert(20%) sepia(243%) saturate(1576%) hue-rotate(-21deg)
              brightness(137%) contrast(100%);
          }
        }
      }
    }
  }

  @include breakpoint(sm) {
    h6 {
      font-size: 18px;
      line-height: 26px;
    }

    p {
      font-size: 14px;
    }
  }

  @include breakpoint(vs) {
    max-width: 50%;
    flex-basis: 50%;
  }
}

//search element auto suggest
.search-dropdown {
  position: absolute;
  border-radius: 0;
  box-shadow: 0px 10px 15px rgba($black, 0.1);
  background-color: $white;
  color: $body-text;
  outline: 0;
  width: 100%;
  top: 45px;

  &-list {
    width: 100%;
    margin-bottom: 0;
    padding-left: 0;
    list-style: none;

    li {
      padding: 12px;
      cursor: pointer;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    li:hover {
      background-color: $secondary-color-1;
    }

    li span {
      font-weight: bold;
      color: $black;
    }
  }
}

.team-redirect-link {
  text-decoration: none;
  color: $black;

  &:hover {
    text-decoration: none;
    color: $black;
  }
}

.no-data-banner {
  padding: 32px 56px;
  align-items: center;

  @include breakpoint(sm) {
    padding: 16px 24px;
  }

  h1 {
    font-size: 24px;
    line-height: 34px;
    text-transform: uppercase;
    font-weight: 700;
    margin: 0;
    padding: 0;

    @include breakpoint(sm) {
      font-size: 18px;
      line-height: 25px;
    }
  }

  p {
    margin: 12px 0 0 0;
    opacity: 0.9;
    line-height: 24px;
  }
}

.tag-history {
  &__popup {
    min-width: 276px;

    padding: 10px;

    h4 {
      font-weight: 700;

      font-size: 15px;

      line-height: 20px;

      border-bottom: 1px solid #e8e8e8;

      margin: 0 0 8px 0;

      padding: 0 0 8px 0;

      text-transform: uppercase;

      display: flex;

      svg {
        color: $primary-color-3;

        width: 18px;

        margin-right: 6px;
      }
    }
  }

  &__text {
    color: $primary-color-4;

    font-size: 12px;

    text-transform: capitalize;

    margin-left: 4px;
  }

  &__row {
    background: $secondary-color-1;

    margin-bottom: 10px;

    padding: 4px 10px;

    display: flex;

    justify-content: space-between;

    font-size: 12px;

    border-bottom: 1px solid #e8e8e8;

    .tag-info {
      small {
        font-size: 12px;

        text-decoration: line-through;

        color: #a1a1a1;
      }

      strong {
        font-weight: 700;

        line-height: 18px;
      }
    }

    .tag-date {
      color: #a1a1a1;

      font-weight: 600;
    }
  }

  &__val {
    color: $primary-color-4;

    font-size: 12px;

    text-transform: capitalize;

    margin: 0 4px;

    cursor: pointer;
  }

  &__scroll {
    max-height: 180px;
    overflow: auto;
  }
}

.add-range-select {
  min-width: 180px;
  margin-right: 16px;
  position: relative;
  .MuiAutocomplete-popper {
    min-width: 300px;
    transform: inherit !important;
    top: 56px !important;
    .MuiPaper-elevation {
      border-radius: 0;
    }
    ul {
      li {
        position: relative;
        padding-right: 32px;
        span {
          font-size: 16px;
          font-weight: 700;
          text-transform: initial;
          display: block;
          svg {
            position: absolute;
            right: 16px;
            top: 8px;
            width: 18px;
            color: $primary-color-1;
          }
        }
        &.selected {
          background-color: $secondary-color-1;
        }
      }
    }
    .add-btn {
      width: calc(100% - 28px);
      margin: 16px 0 16px 16px;
    }
  }
  &.add-edit-range {
    .MuiAutocomplete-popper {
      min-width: inherit;
    }
  }
}
