@import "../../assets//scss/index.scss";
$tablet: 768px;
$large: 1024px;
$desktop: 1280px;

$spacer : 8px;

.Title {
  margin-bottom: 1rem;
}

.LeftContainer {
  /* padding-left: 60px; */
  background: $secondary-color-3;
}
.LeftSection {
  // padding-left: $spacer * 7.5;
  // height: 100vh;
  // @include padding-left(7);
  // @include breakpoint(sm){
  //   @include padding-left(6);
  // }
  // @include tablet {
  //   padding-left: $spacer * 6.5;
  // }
  /* width: 50%; */
  height: 100vh;
}

.RightSection {
  /* background: red; */
  /* height: 100vh; */
}
.Container {
  display: flex;
  width: 100%;
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  height: 100vh;
}
.LoginFormContainer {
  // display: flex;
  // flex-direction: column;
  // @include padding-left(16.5);
  // @include padding-top(23.25);
  // @include breakpoint(sm){
  //   @include padding-left(12.5);
  //   @include padding-top(17.25);
  // }
}
.LoginLabel {
  @include margin-bottom(2);
  @include font(36, 300,  44)
}
.StyledLogo {
  // @include breakpoint(sm){
  //   width: 160px;
  //   height: 56px;
  // }
}
.LoginArtWork {
  padding: 100px;
  @include breakpoint(sm) {
    padding: 0px;
  }
}
.LoginArtWorkImg {
  width: 100%;
  // @include breakpoint(sm){
  //   width: 337px;
  //   height: 127px;
  // }
}
.inputfiled {
  display: grid;
}
.InputFieldActive{
  border-bottom-color: $secondary-color-2 !important;
  &:after {
    border-bottom-color: $secondary-color-2 !important;
  }
}
.InputFieldError{
  border-bottom-color: $danger-color-1;
  background: $danger-color-2 !important;
}

.InputField {
  max-width: 100%;
  border-radius: 0 !important;
  height: 56px;
  color: $secondary-color-2;
  @include font(15, 400, Regular, 24);
  color: $body-text;
//  @include breakpoint(sm){
//    width: 227px;
//  }
}
// .InputUnderLine {
//   border-bottom-color: $primaryColor !important;
//   color: $primaryColor !important;
//   &:after {
//     color: $primaryColor !important;
//   }
// }

.InputLable {
  
  @include font(15, 400, Regular, 16);
  color: $secondary-color-2 !important;
  top: 3px;
}
.InputLableErr {
  color: $danger-color-1 !important;
}
.btnCont {
  // @include padding-top(9);
  // @include breakpoint(sm){
  //   @include padding-top(6);
  // }
}
.sbtBtn {
  @include submit-btn;
  width: 164px;
  height: 56px;
  // border-radius: 0 !important;
  // font-weight: bold;
  // font-size: 16px;
  // line-height: 22px;
  // align-items: center;
  // text-align: center;
  text-transform: uppercase;
  color: #ffffff !important;
  cursor: pointer;
}
.sbtBtn:focus {
  outline: none !important;
  box-shadow: none !important;
}
.PasswdContainer {
  position: relative;
  width: 100%;
  display: grid;
}
.css-1mjko64-MuiFormControl-root-MuiTextField-root {
  width: 100%;
}
.PassIcon:focus {
  outline: none;
}
.ErrCard {
  background-color: #fddfdf75 !important;
  border-radius: 0 !important;
  box-shadow: none !important;
  height: 150px;
  width: 427px;
}
.CardsContentCont {
  display: flex;
}
.ErrContent {
  display: flex;
  flex-direction: column;
  padding-left: 1.25em;
}
.ErrIcon {
  margin-top: 0.625em;
}
.errMsgCont {
   display: flex;
   align-items: flex-start;
   padding-top: 8px;
  span {
   svg {
    margin-top: 2px;
  }
 }
}

.errMsg {
  font-size: 14px;
  line-height: 22px;
  color: #212121;
  padding-left: 10px;
}


.ErrHeading {
  text-align: center;
  color: $danger-color-1;
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 40px;
  /* identical to box height, or 200% */

  display: flex;
  align-items: center;
  text-transform: capitalize;
}
.ErrMsg {
  @include font(14, 400, normal, 22);
  color: $body-text;
}
.Retry {
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 18px;
  text-transform: uppercase;
  color: #1464a5;
}
.RetryCont {
  cursor: pointer;
}
// .Mui-error:after
// {
//   background-color: red !important;
// }

// @media only screen and (min-width: 768px) and (max-width: 1023px) {
//   .LoginFormContainer {
//     margin-left: 2.5em;
//     margin-top: 1em;
//   }
//   .InputField {
//     width: 350px;
//   }
//   .ErrCard {
//     width: 350px;
//     height: 140px;
//   }
//   .ErrContent {
//     padding-left: 0.625em;
//   }
//   .LoginLabel {
//     margin-top: 0.5em;
//   }
// }
