// White, Black, Grays
$white: rgba(255, 255, 255, 1);
$black: rgba(0, 0, 0, 1);
$body-text: rgb(33, 33, 33); //#212121

$primary-color-1: rgb(36, 121, 205); // #2479cd
$primary-color-2: rgb(38, 137, 13); // #26890d
$primary-color-3: rgba(4, 50, 99, 1); // #043263
$primary-color-4: rgba(0, 122, 255, 1); // #007AFF
$primary-color-5: rgba(131, 0, 196, 1); // #8300C4


$secondary-color-1: rgb(245 ,245, 245); //#f5f5f5;
$secondary-color-2: rgb(102, 102, 102); //#666666;
$secondary-color-3: rgb(235, 244, 255); //#EBF4FF;
$secondary-color-4: rgb(189, 189, 189); //#BDBDBD;

$danger-color-1: rgb(255, 0, 0);  //#FF0000;
$danger-color-2: rgba(253, 223, 223, 0.46);

$warning-color-1: rgba(255, 132, 0, 1); //#FF8400



// $primaryColor: #666666;
// $secondoryColor: #EBF4FF;    
// $dangerColor: ;
// $inputErrorColor: rgba(253, 223, 223, 0.46); #FDDFDF