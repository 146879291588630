/*Transitions*/
@mixin transition($args...) {
  -webkit-transition: $args;
  -moz-transition: $args;
  -ms-transition: $args;
  -o-transition: $args;
  transition: $args;
}
/*
Ex: @include transition(color .3s ease);
*/



/* Clearfix*/
%clearfix {
  *zoom: 1;
  &:before,
  &:after {
    content: " ";
    display: table;
  }
  &:after {
    clear: both;
  }
}


/*Spacing classes*/
$level: 10;
$amount: 8px;

@mixin margin-classes {
  @for $i from 0 through $level {
    $margin: $i * $amount;
    /* margin #{$margin} */
    .ma-#{$i} {
      margin: $margin !important;
    }
    .ml-#{$i} {
      margin-left: $margin !important;
    }
    .mr-#{$i} {
      margin-right: $margin !important;
    }
    .mt-#{$i} {
      margin-top: $margin !important;
    }
    .mb-#{$i} {
      margin-bottom: $margin !important;
    }
    .mx-#{$i} {
      margin-left: $margin !important;
      margin-right: $margin !important;
    }
    .my-#{$i} {
      margin-top: $margin !important;
      margin-bottom: $margin !important;
    }
  }
}

// padding more than 10 levels
@mixin padding-left($val) {
  padding-left: $val * $amount !important;
}

@mixin padding-right($val) {
  padding-right: $val * $amount !important;
}

@mixin padding-top($val) {
  padding-top: $val * $amount !important;
}

@mixin padding($val) {
  padding-top: $val * $amount !important;
  padding-right: $val * $amount !important;
  padding-left: $val * $amount !important;
  padding-bottom: $val * $amount !important;
}


@include margin-classes;

@mixin padding-classes {
  @for $i from 0 through $level {
    $padding: $i * $amount;
    /* padding #{$padding} */
    .pa-#{$i} {
      padding: $padding !important;
    }
    .pa-permission#{$i} {
      padding-top: $padding !important;
      padding-right: $padding !important;
      padding-bottom: $padding !important;
      padding-left: 0px !important;
    }
    .pl-#{$i} {
      padding-left: $padding !important;
    }
    .pr-#{$i} {
      padding-right: $padding !important;
    }
    .pt-#{$i} {
      padding-top: $padding !important;
    }
    .pb-#{$i} {
      padding-bottom: $padding !important;
    }
    .px-#{$i} {
      padding-left: $padding !important;
      padding-right: $padding !important;
    }
    .py-#{$i} {
      padding-top: $padding !important;
      padding-bottom: $padding !important;
    }
  }
}


@mixin margin-top($val) {
  margin-top: $val * $amount !important;
}
@mixin margin-bottom($val) {
  margin-bottom: $val * $amount !important;
}
@mixin margin-left($val) {
  margin-left: $val * $amount !important;
}
@mixin margin-right($val) {
  margin-right: $val * $amount !important;
}



@include padding-classes;
