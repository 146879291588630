@import "./colors";
@import "./mixins";

button:focus {
  outline: none !important;
}

@mixin submit-btn {
  //size, weight, style, height, letter spacing, family
  @include font(16, 700, normal, 22, 0);
  align-self: Center;
  vertical-align: Center;
  fill: Solid;
  border-radius: 0 !important;
  text-transform: uppercase;
  cursor: pointer;
  color: $white;
  display: flex;
  align-items: center;
  text-align: center;
}

.primary-btn,
.primary-btn1,
.secondary-btn {
  background: $primary-color-1;
  box-shadow: none !important;
  border-radius: 0 !important;
  height: 50px;
  padding: 14px 16px;
  min-width: 160px;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 0;
  transition: 0.3s all;
  font-size: 16px;
  outline: none !important;
  font-family: "Open Sans", sans-serif;

  &:hover {
    opacity: 0.8;
    background: $primary-color-3;
  }

  &.MuiButton-containedSizeSmall {
    font-size: 12px;
    min-width: inherit;
    height: inherit;
    padding: 9px 12px;
    line-height: 16px;
  }

  &.Mui-disabled {
    background: #f5f5f5;
    color: #bdbdbd;
  }

  &.MuiButton-text {
    background: inherit;
    padding: 0;
    min-width: inherit;
    height: auto;
  }

  .MuiTouchRipple-root {
    display: none;
  }

  @include breakpoint(sm) {
    font-size: 14px;
  }
}

.secondary-btn {
  color: $primary-color-1;
  background: #f5f5f5;

  &:hover {
    opacity: 0.8;
    background: #f5f5f5;
  }

  &.MuiButton-text {
    color: #212121;

    &:hover {
      background: transparent;
    }
  }
}

.icon-btn {
  width: 44px;
  height: 44px;
  background: whitesmoke;
  border-radius: 0;
  color: black;

  &.small {
    width: 30px;
    height: 30px;

    svg {
      font-size: 20px;
    }
  }

  &.Mui-disabled {
    opacity: 0.5;
  }

  &.MuiCircularProgress-root {
    position: relative;
    top: 6px;
  }
}

.toggle-buttons {
  border-radius: 0px;
  border: 1px solid $primary-color-4;

  button {
    border-radius: 0;
    border: none !important;
    margin: 1px;
    font-weight: 700;
    font-family: "Open Sans", sans-serif;
    font-size: 12px;
    padding: 8px 16px;

    &.Mui-selected {
      background: $primary-color-4 !important;
      color: $white;
    }

    &:hover {
      //background: rgba($primary-color-4, 0.8) !important;
    }

    @include breakpoint(sm) {
      width: auto !important;
      min-width: auto;
    }
  }

  span {
    display: none !important;
  }
}
