@import "./colors";

.custom-alert {
  width: 100%;
  background: #eff8ff;
  border-radius: 0;
  box-shadow: none;
  padding: 24px;
  font-family: "Open Sans", sans-serif;
  &__title {
    font-family: inherit;
    font-size: 20px;
    line-height: 34px;
    font-weight: 600;
    padding: 0;
    margin: 0 0 5px 0;
    color: #121212;
  }

  .MuiAlert-icon {
    padding: 0;
    margin: 0 20px 0 0;
    svg {
      width: 28px;
      height: 28px;
      position: relative;
      top: 5px;
      color: #043263;
    }
  }

  .MuiAlert-message {
    padding: 0;
  }
  .MuiAlert-action {
    align-items: center;
    padding-right: 40px;
    button {
      background: white;
      color: #2479cd;
      min-width: 190px;
      &.Mui-disabled{
        opacity: 0.8;
        color: #bdbdbd;
      }
    }
  }
  p {
    font-size: 15px;
    line-height: 24px;
    margin: 0;
    padding: 0;
    opacity: 0.9;
    color: #212121;
  }
  &.MuiAlert-standardWarning {
    background: #fff5df;
    .MuiAlert-icon svg {
      color: #df981a;
    }
  }
  &.MuiAlert-standardError {
    background: #fddfdf;
    .MuiAlert-icon svg {
      color: #ff0000;
    }
  }
  &.MuiAlert-standardSuccess {
    background: #DAEFE0;
    .MuiAlert-icon svg {
      color: #48AE64;
    }
  }
  @include breakpoint(sm) {
    padding: 16px;
    .MuiAlert-action {
      padding-right: 8px;
      button {
        min-width: 170px;
        font-size: 14px;
        height: 40px;
      }
    }
    &__title {
      font-size: 15px;
      line-height: 24px;
      font-weight: 700;
    }
    p {
      font-size: 14px;
      line-height: 22px;
    }
  }
  &.top {
    position: fixed;
    top: 10px;
    left: 0;
    right: 0;
    z-index: 100;
    margin: auto;
    width: 630px;
    .MuiAlert-action {
      position: absolute;
      top: 12px;
      right: 20px;
      padding: 0;
      button {
        min-width: 0;
        background: transparent;
        padding: 0;
      }
    }
  }
}

.check-list {
  list-style: none;
  margin: 0;
  padding: 0;
  li {
    border-bottom: 1px solid rgba($black , 0.1);
    padding: 16px 0;
    font-size: 18px;
    line-height: 26px;
    svg {
      margin-right: 16px;
      font-size: 26px;
    }
    @include breakpoint(sm) {
      font-size: 15px;
    line-height: 24px;
    }
    small {
      color: $primary-color-3;
      font-size: 14px;
      display: block;
      margin-left: 40px;
      line-height: 22px;
      font-weight: 400;
      margin-top: 2px;
    }
    .override-btn {
      text-align: right;
      display: inline-block;
      width: 280px;
      .primary-btn {
        font-size: 12px;
        line-height: 16px;
        svg {
          font-size: 18px;
          margin-right: 6px;
        }
      }
    }
    .text-block {
      display: inline-block;
      width: calc(100% - 290px);
    }
  }
}
.inventory-banner {
  .custom-alert {
    padding: 24px 0;
  }
}
.Toastify {
  &__toast {
    margin-bottom: 0 !important;
    min-height: inherit !important;
    padding: 16px !important;
    border-radius: 0 !important;
    font-family: "Open Sans", sans-serif !important;
    color: #212121 !important;
    &-container {
      min-width: 500px;
      padding: 0 !important;
      border-radius: 0;
      top: 0 !important;
    }
    &-body {
      align-items: start !important;
      font-size: 15px;
      font-weight: 400;
      line-height: 24px;
      padding: 0 !important;
      margin: 0 !important;
    }

    &-icon {
      margin-right: 16px !important;
      svg {
        width: 22px;
      }
    }
    &--error {
      background: #fddfdf !important;
      .Toastify__toast-icon{
        color: red !important;
      }
    }
  }
  &__close-button {
    color: #212121 !important;
    opacity: 0.5 !important;
    margin-left: 16px;
    position: relative;
    top: -14px;
    right: -8px;
    svg {
      height: 16px !important;
      width: 16px !important;
    }
  }
}