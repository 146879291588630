@import "./colors";

.custom-dialog {
  .MuiDialog-paper {
    padding: 40px 56px 80px 56px;
    border-radius: 0;
    overflow: hidden;
    max-width: 880px;
  }

  &__header {
    flex-direction: column;

    &-close {
      text-align: right;

      button {
        font-weight: 400;
        height: auto;

        svg {
          font-size: 24px !important;
        }
      }
    }

    h2 {
      font-size: 32px;
      line-height: 44px;
      font-weight: 700;
      margin: 0;
      padding: 0 0 16px 0;
      @include breakpoint(sm) {
        font-size: 24px;
        line-height: 34px;
      }
    }
  }

  &__actions {
    margin: 40px 0 0 0;
    padding: 0;
    @include breakpoint(sm) {
      button {
        height: 40px;
        font-size: 14px;
      }
    }
  }

  &__cont {
    padding: 40px 0 80px 0;
    border-top: 1px solid #bdbdbd;
    border-bottom: 1px solid #bdbdbd;
    max-height: calc(100vh - 370px);
  }

  .separator {
    align-items: center;
    justify-content: center;
    display: flex;

    span {
      width: 14px;
      height: 3px;
      background: #bdbdbd;
      display: flex;
    }
  }

  .close-icon {
    font-size: 28px;
    color: black;
  }
  @include breakpoint(sm) {
    .MuiDialog-container {
      align-items: flex-start;
    }
    .MuiDialog-paper {
      max-width: 600px;
    }
  }
}

.custom-input {
  width: 100%;

  .MuiFilledInput-root {
    border-radius: 0;
    height: 56px;
  }
  &.text-area {
    .MuiFilledInput-root {
      height: auto;
      textarea {
        min-height: 80px;
        padding-top: 6px;
      }
    }
  }

  .MuiInputLabel-root {
    font-family: inherit;
    top: 2px;
  }

  .MuiFilledInput-input,
  .MuiSelect-select {
    font-family: "Open Sans", sans-serif;
  }
  &.light {
    .MuiFilledInput-root {
      background: white;
    }
  }
  &.filled {
    label {
      transform: translate(12px, 7px) scale(0.75);
    }
  }
}

.custom-select {
  position: relative;

  .MuiSelect-icon {
    display: none;
  }

  &:before {
    content: "";
    border: solid black;
    border-width: 0 2px 2px 0;
    display: inline-block;
    padding: 4px;
    position: absolute;
    transform: rotate(45deg);
    right: 20px;
    top: 22px;
    z-index: 1;
    pointer-events: none;
  }
  .MuiAutocomplete-endAdornment {
    display: none;
  }
  .MuiInputBase-fullWidth {
    padding: 12px 0px 0 2px !important;
  }
  .MuiInputLabel-formControl {
    padding-right: 40px;
  }
  input {
    padding: 16px 32px 0 12px !important;
  }
}
.custom-radio-group {
  label {
    span {
      font-family: "Open Sans", sans-serif;
      font-size: 15px;
      line-height: 24px;
    }
  }
  &.no-wrap {
    label span {
      white-space: nowrap;
    }
  }
}

.MuiMenu-paper,
.MuiAutocomplete-popper {
  border-radius: 0;
  box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.1);
  margin-top: 4px;

  ul {
    margin: 0 0 72px 0;
    padding: 0;
    max-height: 300px;

    li {
      font-family: "Open Sans", sans-serif;

      &:hover {
        background: #ebf4ff;
      }
      .MuiTouchRipple-root {
        display: none;
      }
      &.row-edit-list {
        min-width: 240px;
        font-weight: 600;
        font-size: 16px;
        padding: 10px 16px;
        &:last-child {
          margin-bottom: -72px;
        }
        &:hover {
          background: whitesmoke;
        }
      }
    }

    .dropdown-list {
      padding: 0;
      font-family: "Open Sans", sans-serif;
      background: transparent;

      small {
        font-size: inherit;
        border-bottom: 1px solid lightgray;
        display: inline-block;
        width: calc(100% - 32px);
        padding: 13px 0;
        margin: 0 16px;
        font-weight: 400;
        color: #666;
        min-height: 52px;
      }

      .MuiTouchRipple-root {
        display: none;
      }

      &:hover {
        background: whitesmoke;
      }
    }
  }
}
.MuiAutocomplete-popper {
  ul {
    margin-bottom: 0;
  }
}

.filter-menu {
  .MuiMenu-paper {
    margin-top: 0;

    ul {
      min-width: 177px;
      margin-bottom: 0;

      li {
        padding: 12px;
        font-size: 16px;
        font-weight: 600;
        line-height: 24px;
      }
    }
  }
}
.filter-dropdown {
  .MuiMenu-paper {
    width: 360px;
    margin-top: 12px;
    ul {
      margin: 0;
      li {
        padding: 0;
        span {
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
          font-family: inherit;
          font-size: 14px;
        }
      }
    }
  }
  &__cont {
    max-height: 200px;
    overflow: auto;
  }
  &__actions {
    padding: 16px 16px 8px 16px;
    width: 100%;
    border-top: 1px solid $secondary-color-1;
    margin-top: 10px;
    button {
      font-size: 12px;
    }
  }
}
.clsInventory {
  .MuiMenu-paper {
    margin-top: 15px;
    min-width: 172px;
    ul {
      margin: 0;
      padding: 8px;
    }
  }
}
