@import "./colors";

.custom-spinner {
  width: 100%;
  position: fixed;
  height: 100vh;
  background: rgba(0, 0, 0, 0.8);
  z-index: 9999;
  left: 0;
  top: 0;
  margin: auto;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  &__cancel {
    color: white;
    text-transform: uppercase;
    margin-top: 32px;
    display: inline-block;
    font-weight: 600;
    letter-spacing: 1px;
    opacity: 0.9;
    cursor: pointer;
    &:hover {
      opacity: 1;
    }
  }
}

/* barcode container */
.anim-box {
  position: relative;
  width: 160px;
  height: 80px;
  padding: 10px;
  transition: transform 0.6s ease-out;
}

.anim-box:before,
.anim-box:after,
.anim-box > :first-child:before,
.anim-box > :first-child:after {
  position: absolute;
  width: 10%;
  height: 15%;
  border-color: #a1a1a1;
  border-style: solid;
  content: " ";
}

.anim-box:before {
  top: 0;
  left: 0;
  border-width: 2px 0 0 2px;
}

.anim-box:after {
  top: 0;
  right: 0;
  border-width: 2px 2px 0 0;
}

.anim-box > :first-child:before {
  bottom: 0;
  right: 0;
  border-width: 0 2px 2px 0;
}

.anim-box > :first-child:after {
  bottom: 0;
  left: 0;
  border-width: 0 0 2px 2px;
}

.anim-item {
  display: inline-block;
  background-color: #a1a1a1;
  height: 40px;
  margin-top: 5px;
}

.anim-item-sm {
  width: 2px;
  margin-right: 3px;
}

.anim-item-md {
  width: 3px;
  margin-right: 2px;
}

.anim-item-lg {
  width: 5px;
  margin-right: 5px;
}

.scanner {
  width: 120px;
  height: 5px;
  background-color: #1464a5;
  opacity: 1;
  position: relative;
  margin: auto;
  top: 50%;
  animation-name: scan;
  animation-duration: 3s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  animation-play-state: running;
}

@keyframes scan {
  0% {
    box-shadow: 0px 0px 0px 0px rgba(20, 100, 165, 0.49);
    top: 50%;
  }
  25% {
    box-shadow: 0px 0px 0px 0px rgba(20, 100, 165, 0.49);
    top: 5px;
  }
  75% {
    box-shadow: 0px 0px 0px 0px rgba(20, 100, 165, 0.49);
    top: 85%;
  }
}
