@import "../../assets/scss/index.scss";


.Container{
    padding: 10px 0;
    box-shadow: inset 0px -1px 0px rgba(219, 219, 219, 0.7);
    position: fixed;
    top: 80px;
    left: 0;
    width: 100%;
    background: $white;
    margin-bottom: 10px;
    z-index: 9;
    .ContainerInner {
        width: 100%;
        display: flex;
        justify-content: space-between;
        @include breakpoint(sm) {
            flex-wrap: wrap;
        }
    }
}
.inventoryBanner {
    position: fixed;
    top: 80px;
    z-index: 9;
    background: #fddfdf;
    left: 0;
    width: 100%;
    @include breakpoint(sm) {
        // adjusts inventory closed banner 
        top: 104px;
    }
    @include breakpoint(vs) {
        // adjusts inventory closed banner 
        top: 106px;
    }
}