@import "../../assets/scss/index.scss";


.Container{
    padding: 10px 0;
    box-shadow: inset 0px -1px 0px rgba(219, 219, 219, 0.7);
    position: fixed;
    top: 80px;
    left: 0;
    width: 100%;
    background: $white;
    margin-bottom: 10px;
    z-index: 9;
    .ContainerInner {
        width: 100%;
        display: flex;
        justify-content: space-between;
        @include breakpoint(sm) {
            flex-wrap: wrap;
        }
    }
}

.ToolInput {
    max-width: 100%;
    border-radius: 0 !important;
    height: 36px;
    color: $secondary-color-2;
    @include font(15, 400, Regular, 24);
    color: $body-text;
}
.Search {
    padding: 0;
    border: 0;
    background-color: $secondary-color-1;
    height: 44px;
    border-radius: 0;
    .SearchControl {
        background-color: $secondary-color-1;
        border-radius: 0;
        border: 0;
        font-size: 15px;
        border-left: 2px solid rgba($black, 0.1);
        padding: 0px 0;
        text-indent: 9px;
        &:focus {
            box-shadow: none;
        }
    }
    .GroupText {
        background-color: $secondary-color-1;
        padding: 0 5px;
        border: 0;
        .ReportsIcon {
            height: 20px;
            vertical-align: sub;
        }
    }
}
.inputLabel {
    margin-top: -5px;
    font-size: 15px;
    color: #717171;
}
.SeacrchField {
    background-color: $secondary-color-1 !important;
    border-radius: 0 !important;
    height: 44px;
    &::before {
        border-bottom: 0 !important;
    }
}
.SeacrchFieldErr{
    background-color: $danger-color-2 !important;
}
.InputBox {
    padding-top: 8px !important;
    font-size: 15px !important;
}
.InputIcon {
    position: relative;
    top: -7px;
    width: auto;
    height: 20px !important;
    border-right: 1px solid rgba($secondary-color-4, 0.5);
}
.FilterButton {
    background-color: $secondary-color-1 !important;
    border-radius: 0 !important;
    min-height: 44px;
    svg {
        font-size: 24px !important;
        margin-right: 4px;
        }
}
.PrimaryButton {
    height: 44px;
    svg {
        font-size: 24px !important;
        fill: white;
        stroke: white;
        stroke-width: 2px;
    }
}
.inventoryBanner {
    position: fixed;
    top: 80px;
    z-index: 9;
    background: #fddfdf;
    left: 0;
    width: 100%;
    @include breakpoint(sm) {
        // adjusts inventory closed banner 
        top: 104px;
    }
    @include breakpoint(vs) {
        // adjusts inventory closed banner 
        top: 106px;
    }
}