.custom-progressbar {
  position: relative;

  &__bar {
    height: 30px;
    background: white;

    &.not-started {
      & + span {
        background: #cecece !important;
        color: #454545;
      }
      // .MuiLinearProgress-bar {
      //   background: #cecece !important;
      //   @include breakpoint(sm) {
      //     transform: translateX(-85%) !important;
      //   }
      // }
    }
  }

  &__text {
    position: absolute;
    left: 0;
    top: 0;
    color: white;
    font-size: 12px;
    font-weight: 700;
    padding-right: 6px;
    min-width: 30px;
    text-align: right;
    line-height: 30px;
  }

  &.countProgress {
    .MuiLinearProgress-bar,
    .custom-progressbar__text {
      background: $primary-color-3;
    }
  }

  &.auditProgress {
    .MuiLinearProgress-bar,
    .custom-progressbar__text {
      background: $primary-color-4;
    }
  }

  &.varianceProgress {
    .MuiLinearProgress-bar,
    .custom-progressbar__text {
      background: $primary-color-5;
    }
  }

  &.teamEfficiency {
    .MuiLinearProgress-bar {
      background: #ff0000;
    }
  }
}

.orange-bar {
  .custom-progressbar {
    .MuiLinearProgress-bar {
      background: #ff8400;
    }
  }
}

.green-bar {
  .custom-progressbar {
    .MuiLinearProgress-bar {
      background: $primary-color-2;
      transform: none !important;
    }
  }
}

.red-bar {
  .custom-progressbar {
    .MuiLinearProgress-bar {
      background: $danger-color-1;
    }
  }
}

.audit-progressbar {
  background-color: $primary-color-4;
  &.light {
    background-color: rgba($primary-color-4, 0.75);
  }
}

.variance-progressbar {
  background-color: $primary-color-5;

  &.light {
    background-color: rgba($primary-color-5, 0.75);
  }
}

.gray-progressbar {
  background-color: #cecece;
}

.green-progressbar {
  background-color: #008800;
}

.count-progressbar {
  background-color: $primary-color-3;
  &.light {
    background-color: rgba($primary-color-3, 0.75);
  }
}
.team-efficiency-bar {
  height: 30px;
  background: white;
  width: 100%;
  display: flex;
  position: relative;
  &__red {
    width: 25%;
    background: #ff0000;
  }
  &__orange {
    width: 50%;
    display: inline-flex;
    background: #ffc000;
    align-items: center;
    justify-content: right;
  }
  &__green {
    width: 25%;
    background: #92d050;
  }
  &__line {
    border-radius: 4px;
    height: 30px;
    position: absolute;
    left: 0%;
    top: 0;
    z-index: 1;
    display: flex;
    align-items: center;
    &:before {
      content: "";
      width: 6px;
      height: 34px;
      position: absolute;
      left: 0px;
      top: -2px;
      background: white;
      z-index: 1;
      border-radius: 3px;
    }
    &:after {
      content: "";
      width: 2px;
      height: 30px;
      left: 2px;
      top: 0;
      position: absolute;
      background: black;
      z-index: 1;
    }
    label {
      font-weight: 700;
      font-size: 12px;
      color: white;
      margin-left: 10px;
      &.text-left {
        margin-left: -100%;
        padding-right: 4px;
      }
    }
  }
}
.team-efficiency-count {
  font-size: 12px;
  font-weight: 400;
  justify-content: space-between;
  display: flex;
}
