$width-xs: 767px; // Small Mobiles
$width-vs: 1023px; // Mobiles Landscape
$width-sm: 1920px; // Tablet
$width-md: 1920px; // Tablet to laptop
$width-lg: 1920px; // desktop
$width-vl: 1920px; // desktop
$width-xl: 1920px; // 4k and above screens
$width-xxl: 1920px; // 4k and above screens

@mixin breakpoint($class) {
  @if $class == xs {
    @media (max-width: $width-xs) {
      @content;
    }
  } @else if $class == vs {
    @media (max-width: $width-vs) {
      @content;
    }
  } @else if $class == sm {
    @media (max-width: $width-sm) {
      @content;
    }
  } @else if $class == md {
    @media (min-width: $width-md) {
      @content;
    }
  } @else if $class == lg {
    @media (min-width: $width-lg) {
      @content;
    }
  } @else if $class == vl {
    @media (min-width: $width-vl) {
      @content;
    }
  } @else if $class == xl {
    @media (min-width: $width-xl) {
      @content;
    }
  } @else if $class == xxl {
    @media (min-width: $width-xxl) {
      @content;
    }
  } @else {
    //sass-lint:disable no-warn
    @warn 'Breakpoint mixin supports: xs, sm, md, lg, xl, only-xs, only-sm, only-lg';
    //sass-lint:enable no-warn
  }
}

// Custom devices
@mixin breakpoint-custom($screen) {
  @media (min-width: $screen+"px") {
    @content;
  }
}

/*
 Usage of breakpoints
.abc{
  padding:10px;
  @include breakpoint(sm) {
    padding: 0
  }
  @include breakpoint-custom(530) {
    padding: 0
  }
}
 */
.main-container {
  margin: auto;
  padding: 0 16px;
  @include breakpoint(md) {
    max-width: 1180px;
    padding: 0;
  }
  @include breakpoint(lg) {
    max-width: 1246px;
  }
  @include breakpoint(vl) {
    max-width: 1320px;
  }
  @include breakpoint(xl) {
    max-width: 1480px;
  }
  @include breakpoint(xxl) {
    max-width: 1760px;
  }
}

.visible-tab {display: none;}
@include breakpoint(sm) {
  .visible-tab {
    display: block;
  }
  .custom-table-responsive {
    // we no longer want individual scroll bar tables in tag details page
    // overflow: auto;
    // height: auto;
    // max-height: calc(100vh - 520px);
  }
  .new-range-row {
    flex-wrap: nowrap;
  }
  .duplicate-tags {
    .tag-list.type-2 .tag-list__col {
      margin-right: 14px;
    }
  }
  .variance-header {
    &__cont {
      min-width: 100%;
      width: 100%;
      justify-content: space-between; 
    }
    &__actions{
      padding-top: 16px;
      min-width: 100%;
      justify-content: flex-start !important;
    }
  }
}
@include breakpoint(sm) {
  .duplicate-tags {
    min-width: auto;
    &__col {
      width: 50%;
      .custom-table th {
        min-width: 70px !important;
      }
    }
  }
}
.sidebyside
{
   flex-wrap: nowrap;
   
}
.duplicateTagset
{
  min-width: 700px; 
}
.nonDuplicateHegiht
{
  min-height: 200px;
}