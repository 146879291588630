@import "./colors";

.FooterContainer {
  background: $secondary-color-1;
  flex-shrink: 0;
  padding: 24px 60px 36px;
  z-index: 1;
  position: fixed;
  bottom: 0;
  width: 100%;
  left: 0;
  @include breakpoint(sm) {
    padding: 16px;
    .custom-progressbar {
      &__bar {
        height: 24px;
      }
      &__text {
        top: 0;
        line-height: 24px;
      }
    }
  }
}
.ProgressSection {
  strong {
    text-transform: uppercase;
    font-size: 0.9rem;
    display: block;
    @include breakpoint(sm) {
      font-size: 12px;
    }
  }
  small {
    display: block;
    font-size: 12px;
  }
}
// .chatbot-btn{
//   right: 17px;
//   bottom: -15px;
//   width: 80px;
//   display: inline-block;
//   position: absolute;
//   z-index: 100;
//   height: 80px;
//   text-align: center;
//   border-radius: 0px;
//   cursor: pointer;
// } 
.footer-btn {
  cursor: pointer;
  display: inline-block;
  border: 1px solid rgba($black, 0.1);
  border-radius: 50px;
  position: absolute;
  bottom: -62px;
  z-index: 100;
  width: 30px;
  height: 30px;
  text-align: center;
  right: 20px;
  svg {
    font-size: 20px;
    color: rgba($black, 0.4);
		position: relative;
    top: 1px;
  }
	&:hover {
		background: white;
	}
  @include breakpoint(sm) {
    bottom: -42px;
    right: 15px;
    svg {
      top: 4px;
    }
  }
  @include breakpoint(sm) {
    bottom: -27px;
  }
}

.GridItem {
  padding-top: 12px !important;
}

.footer-collapse {
  & + .FooterContainer {
    padding: 10px 60px 18px;
    border-top: 1px solid rgba($black, 0.05);
    .ProgressSection {
      small,
      strong {
        display: none;
      }
    }
    .custom-progressbar {
      &__bar {
        height: 20px;
      }
      &__text {
        top: 0;
        height: 20px;
        line-height: 20px;
        small {
          display: inline-block;
          font-size: 12px;
          vertical-align: middle;
          position: relative;
          top: -2px;
          line-height: normal;
         }
        }
    }
    @include breakpoint(sm) {
      padding: 16px 56px 16px 16px;
    }
    .team-efficiency-bar {
      height: 20px;
      &__line {
        height: 20px;
        &:before {
          height: 24px;
        }
        &:after {
          height: 20px;
        }
      }
    }
    .team-efficiency-count {
      display: none;
    }
  }
  .main-wrapper {
    // setting the height to remove the white space b/w container and footer
    height: calc(100vh - 190px);
    min-height: calc(100vh - 190px);
    @include breakpoint(vs) {
      // setting the height to remove the white space b/w container and footer
      height: calc(100vh - 190px);
      min-height: calc(100vh - 190px);
    }
    &.is-inventory {
      // setting the height to remove the white space b/w container and footer
      height: calc(100vh - 276px);
      min-height: calc(100vh - 276px);
      @include breakpoint(vs) {
        // setting the height to remove the white space b/w container and footer
        height: calc(100vh - 276px);
        min-height: calc(100vh - 276px);
      }
    }
  }

  .footer-btn {
    width: 25px;
    height: 25px;
		bottom: 10px;
    svg {
      font-size: 16px;
      transform: rotate(180deg);
      top: -3px;
    }
    @include breakpoint(sm) {
      bottom: -27px;
    }
  }
}
