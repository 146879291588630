@import "./colors";

.custom-accordion {
  background: $secondary-color-1;
  margin-bottom: 16px;
  border-radius: 0 !important;
  position: relative;

  &::before {
    display: none;
  }

  &__row {
    justify-content: space-between;
    align-items: center;

    h3 {
      font-size: 20px;
      line-height: 30px;
      font-weight: 600;
      margin: 0;
      padding: 0;
    }

    strong {
      display: inline-flex;
      font-weight: 600;
      margin: 0;
      padding: 0;
    }

    .MuiGrid-root {
      display: flex;
      align-items: center;
    }
  }

  .MuiAccordionSummary-gutters {
    padding: 0 32px 0 48px;
    min-height: 60px;
    box-shadow: 0px 1px 1px rgba($black, 0.15);

    &.Mui-expanded {
      box-shadow: none;

      .MuiAccordionSummary-content {
        margin: 0;
      }
    }
  }

  .MuiAccordionSummary-expandIconWrapper {
    transform: rotate(270deg);
    left: 16px;
    position: absolute;

    svg {
      width: 28px;
      height: 28px;
      color: $body-text;
    }
  }

  .MuiAccordionDetails-root {
    padding: 0px;
  }

  @include breakpoint(sm) {
    .MuiAccordionSummary-gutters {
      padding: 0 16px 0 40px;
    }

    .MuiAccordionSummary-expandIconWrapper {
      left: 8px;
    }

    .custom-progress {
      margin: 0 0 0 24px;
    }

    &__row {
      h3 {
        font-size: 18px;
        line-height: 26px;
      }
    }
  }

  &__details {
    background: $white;
    border: 1px solid $secondary-color-1;
    padding: 24px;
  }

  &.right {
    box-shadow: none;

    .MuiAccordionSummary {
      &-gutters {
        padding: 0 24px 0 24px;
        box-shadow: 0px 1px 1px $white;

        .Mui-expanded {
          margin: 12px 0;
        }

        .summary-btn {
          justify-content: end;
          padding-right: 40px;

          .primary-btn {
            background: $white;
            color: $primary-color-1;
            min-width: 190px;
          }
        }
      }

      &-expandIconWrapper {
        right: 16px;
        left: inherit;
      }
    }
  }
  &.dark {
    &.validates {
      border: none !important;
      box-shadow: none;
      .MuiAccordionSummary-gutters {
        box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.15);
        padding: 4px 24px;
        &.Mui-expanded {
          box-shadow: none;
          border: none;
        }
      }
      .MuiAccordionSummary-expandIconWrapper {
        left: inherit;
        right: 24px;
      }
      .MuiAccordionSummary-content {
        flex-direction: column;

        .MuiFormControlLabel-label {
          font-size: 14px;
        }

        .MuiFormGroup-row {
          input {
            z-index: 0;
          }
        }
      }

      .MuiAccordionDetails-root {
        border: 1px solid #e8e8e8;
        padding: 24px;
        background: white;

        ul {
          margin: 0;
          padding: 0;
          list-style: none;

          li {
            font-size: 15px;
            line-height: 22px;
            margin-bottom: 12px;
            position: relative;
            padding-left: 20px;

            &:before {
              content: "";
              width: 6px;
              height: 6px;
              background: #00295f;
              position: absolute;
              left: 0;
              top: 9px;
              border-radius: 100px;
            }
          }
        }
      }

      h5 {
        margin: 0;
        padding: 0;
        font-size: 16px;
        line-height: 24px;
        font-weight: 700;
      }

      &.info {
        .MuiAccordionSummary-gutters {
          background: #cfe2fc;
        }
      }

      &.warning {
        .MuiAccordionSummary-gutters {
          background: #fce5ce;
        }
      }

      &.success {
        .MuiAccordionSummary-gutters {
          background: #dbebd4;
        }
      }

      &.error {
        .MuiAccordionSummary-gutters {
          background: #f0b3b3;
        }
      }
    }
  }
}

.custom-progress {
  display: inline-flex;
  min-width: 134px;
  margin: 0 0 0 24px;
  position: relative;

  div {
    width: 100% !important;
    height: 30px !important;
    overflow: hidden;

    span {
      height: 100%;
      display: block;
      font-weight: 700;
      text-align: right;
      line-height: 30px;
      padding-right: 2px;

      strong {
        color: white;
        font-weight: 600;
      }

      &.gray-progressbar {
        width: 100% !important;

        span {
          text-align: center;
          padding-right: 0;
          color: #454545;
        }
      }
    }

    p {
      margin: 0;
      font-family: inherit;
      font-weight: 600;
      padding: 0;
      line-height: 30px;

      small {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;

        strong {
          background: rgba($white, 0.8);
          box-shadow: inset -0.5px -0.5px 0px rgba($black, 0.25);
          display: inline-block;
          padding: 0 4px;
          color: $black;
          font-weight: 700;
          line-height: normal;
        }
      }
    }
  }

  &.dark {
    div {
      p small {
        color: white;
      }
    }
  }

  &.light {
    div {
      p small {
        span {
          color: rgba($black, 0.7) !important;

          &.white-text {
            color: white !important;
          }
        }
      }
    }
  }

  &.red-bar {
    div {
      span {
        background: $danger-color-1;
      }
    }
  }

  &.orange-bar {
    div {
      span {
        background: #ff8400;
      }
    }
  }

  &.green-bar {
    div {
      span {
        background: $primary-color-2;
      }
    }
  }
}

.custom-table {
  @include breakpoint(sm) {
    &.table-scroll {
      th {
        // to remove the horizontal scroll bar
        // min-width: 98px !important;
      }
    }
    //table-layout: fixed;

    .status-bg {
      font-size: 10px;
      max-width: inherit;
      min-width: inherit;
      padding: 0 6px;
    }

    .void-status {
      font-size: 12px;
      line-height: 20px;

      svg {
        width: 16px;
        height: 16px;
      }
    }

    &.layout-auto {
      table-layout: auto;
    }
  }

  &__col {
    overflow: auto;
  }
  thead {
    position: sticky;
    z-index: 1;
    top: -1px;
    box-shadow: inset 0 1px 0 rgba(0, 0, 0, 0.08), inset 0 -1px 0 rgba(0, 0, 0, 0.08);
  }
  td,
  th {
    font-family: inherit;
    font-size: 16px;
    padding: 6px 18px;
    border: 1px solid rgba($black, 0.08);
    line-height: 1;
    height: auto;
    position: relative;

    a {
      color: $primary-color-1;
    }

    @include breakpoint(sm) {
      font-size: 12px;
      padding: 4px;
      line-height: normal;
      word-break: break-word;
      min-width: 40px !important;
    }

    .primary-text {
      color: $primary-color-1;
      font-weight: 700;

      a {
        text-decoration: none;
        color: $primary-color-4;
        text-transform: capitalize;

        &:hover {
          opacity: 0.8;
        }
      }
    }

    &.actions {
      img {
        max-width: 24px;

        &:hover {
          opacity: 0.6;
        }

        &.disabled {
          opacity: 0.4;
        }
      }
    }
  }

  th {
    background: #f5f5f5;
    font-weight: 700;
    font-size: 16px;
    height: 58px;
    text-transform: uppercase;
    padding: 6px 18px;
    line-height: normal;

    &.text-right {
      .custom-table__sort-icon {
        & + span {
          padding-right: 20px;
        }

        &:empty + span {
          padding-right: 0;
        }
      }
    }

    @include breakpoint(sm) {
      font-size: 12px;
      padding: 4px;
    }
  }

  .strike-through td {
    text-decoration: line-through 1px red;
    .qty-input{
      cursor: not-allowed;
      background: none;
    }
    .qty-field{
      cursor: not-allowed;
    }

    &.dlt-txt {
      color: red;
      text-decoration: none;
    }
  }

  .strike-through {
    text-decoration: line-through 1px red;
  }

  .custom-progress {
    width: 188px;
  }

  &.count-list {
    .list-name,
    .list-desc {
      width: 20%;
      word-break: break-word;
    }
  }

  &.sticky-table {
    min-width: 1470px;
    min-height: inherit;

    @include breakpoint(sm) {
      //min-width: 100%;
    }

    td,
    th {
      &:last-child {
        width: 140px;
        background: white;
        position: sticky;
        right: 0;
        z-index: 9;
        text-align: center;

        @include breakpoint(sm) {
          width: auto;
        }
      }
    }

    th:last-child {
      background: #f5f5f5;
    }
  }

  &__sort {
    // display: inline-block;
    display: inline;

    &-icon {
      position: absolute;
      right: 10px;
      top: 18px;

      &.with-filter {
        right: 40px;
      }
    }

    &-icon1 {
      position: absolute;
      right: 10px;
      top: 12px;

      img {
        margin-top: 4px;
      }
    }
  }

  &__filter {
    position: absolute;
    right: 10px;
    top: 14px;
  }

  &__filter1 {
    position: absolute;
    right: 38px;
    top: 12px;
  }

  &-responsive {
    //overflow: auto;
    &.table-layout-auto {
      //overflow-y: hidden;
      .custom-table {
        table-layout: auto !important;
      }
    }
  }

  &.active {
    svg {
      color: $primary-color-1;
    }
  }

  .action-btns {
    svg {
      cursor: pointer;
    }

    .delete-icon {
      margin-right: 8px;
    }
  }

  &.clickable-row {
    td {
      cursor: pointer;
    }
  }
  .selected-row {
    box-shadow: 0px 0px 0px 1px #4564D6;
    td {
      border-top: 1px solid #4564D6 !important;
      background:#EBF4FF;
    }
  }
}

.align-top-table {
  .custom-table td {
    vertical-align: top;

    .line-through {
      margin-top: 6px;
      display: inline-block;

      &.edit-source {
        text-decoration: none;
      }
    }
  }
}

.tagDetailsHistory {
  .custom-table {
    table-layout: inherit !important;
  }
}

.white-bg {
  .custom-table {
    th {
      background: $white;
    }
  }
}

.sticky-table {
  max-height: calc(100vh - 550px);
  overflow: auto;

  @include breakpoint(sm) {
    width: 100%;
  }

  .custom-table {
    th {
      position: sticky;
      top: -1px;
      z-index: 1;
    }
  }
}

.footer-collapse {
  .sticky-table {
    max-height: calc(100vh - 470px);
  }
}

.gray-bg-row {
  margin-bottom: -70px;
}

.custom-data-grid {
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  border-radius: 0;
  border: none;
  min-height: 120px;

  &-col {
    //height: 100vh;
    position: relative;

    .filter-btn {
      position: absolute;
      right: 32px;
      top: 8px;
      z-index: 9;
      color: black;
    }
  }

  .MuiDataGrid {
    &-row {
      min-height: 58px !important;
      max-height: 58px !important;
      cursor: pointer;
    }

    &-virtualScroller {
      overflow: initial;
      overflow-y: hidden;
    }

    &-columnHeaders {
      background: whitesmoke;
      font-weight: 700;
      border-top: 1px solid rgba($black, 0.08);
      border-radius: 0;
    }

    &-columnHeaderTitleContainer {
      flex-direction: row !important;
    }

    &-columnHeaderTitle {
      font-weight: 700;
      text-transform: uppercase;
    }

    &-columnSeparator {
      display: none !important;
    }

    &-cell,
    &-columnHeader {
      border-right: 1px solid rgba($black, 0.08);
      border-bottom: 1px solid rgba($black, 0.08);
      padding: 12px 18px !important;
      height: 58px !important;
      min-height: 58px !important;

      &:focus {
        outline: none !important;
      }

      &:first-child {
        border-left: 1px solid rgba($black, 0.08);
      }
    }

    &-columnHeader {
      position: relative;

      &::before {
        content: "";
        width: 0;
        height: 0;
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-bottom: 5px solid black;
        position: absolute;
        right: 16px;
        opacity: 0;
      }

      &--sorted {
        //&:before {transform: rotate(180deg);}
      }

      &[aria-sort="descending"] {
        &:before {
          transform: rotate(180deg);
          opacity: 1;
        }
      }

      &[aria-sort="ascending"] {
        &:before {
          transform: rotate(0deg);
          opacity: 1;
        }
      }

      &:hover {
        &::before {
          opacity: 1;
        }
      }
    }

    &-menuIcon {
      display: none;
    }

    &-iconButtonContainer {
      button {
        display: none;
      }
    }
  }
}

.status-bg {
  background: #cecece;
  padding: 5px 0;
  font-size: 12px;
  font-weight: 700;
  line-height: 18px;
  text-transform: uppercase;
  max-width: 108px;
  min-width: 108px;
  display: flex;
  color: white;
  justify-content: center;
  align-items: center;
  height: 27px;

  svg{
    width: 16px;
    margin-right: 4px;
  }

  &.success {
    background: #008800;
  }

  &.info {
    background: $primary-color-4;
  }

  &.warning {
    background: #ff8400;
  }

  &.error {
    background: #ff0000;
  }

  &.empty {
    background: transparent;
  }

  &.not-started {
    background: $secondary-color-4;
  }

  &.researched {
    background-color: #8300c4;
    color: white !important;

    &.light {
      opacity: 0.75;
    }
  }

  &.variance-progress {
    max-width: 120px;
    min-width: 120px;
    color: #454545;
  }

  &.count-status {
    &.success,
    &.warning {
      background-color: $primary-color-3;
    }
  }

  &.audit-status {
    &.success {
      background-color: $primary-color-4;
    }
  }
  &.team-status {
    &.success {
      background-color: $primary-color-3;
    }
  }
}

.team-name {
  text-decoration: none;

  svg {
    color: $primary-color-2;
    display: inline-block;
    margin-left: 24px;
    width: 20px;
    float: right;
  }

  .status-dot {
    background: gray;
    width: 12px;
    height: 12px;
    border-radius: 50px;
    display: inline-block;
    margin-right: 4px;

    &.active {
      background: $primary-color-2;
    }

    &.inActive {
      background: yellow;
    }

    &.loggedOff {
      background: $danger-color-1;
    }
  }
}

.void-status {
  color: $primary-color-1;
  font-size: 15px;
  line-height: 24px;
  font-weight: 600;
  text-transform: uppercase;
  cursor: pointer;
  display: inline-flex;

  svg {
    margin-right: 4px;
    width: 22px;
  }
}

.void-tooltip-list {
  margin: 0;
  padding: 0;
  list-style: none;
  min-width: 200px;

  li {
    font-size: 12px;
    line-height: 16px;
    margin-bottom: 8px;
    position: relative;
    padding-left: 24px;

    &:last-child {
      margin-bottom: 0;
    }

    &:before {
      width: 12px;
      height: 2px;
      background: $body-text;
      content: "";
      position: absolute;
      left: 0;
      top: 7px;
    }
  }
}

@include breakpoint(sm) {
  .responsive-table {
    width: 100%;
    overflow: auto;

    .custom-table {
      //width: 930px;
    }
  }
}

.custom-search {
  display: inline-flex;
  height: 44px;
  background: whitesmoke;
  margin-right: 16px;
  align-items: center;
  justify-content: center;
  padding: 12px;

  &__search {
    width: 24px;
    color: #727272;
    margin: 0 8px 0 8px;
  }

  &__count {
    font-size: 12px;
    line-height: 16px;
    margin-left: 12px;
  }

  &__icons {
    padding: 0;
    margin: 0 0px 0 12px;

    svg {
      width: 20px;
      height: 20px;
    }
  }

  &__line {
    background: #cecece;
    width: 2px;
    height: 26px;
    margin: 0 0 0 14px;
  }

  input {
    height: 44px;
    padding: 0;
    border-bottom: none;
    background: whitesmoke;
    font-family: "Open Sans", sans-serif;
    width: 115px;
    @include breakpoint(vs) {
      width: 100px;
    }
  }

  .MuiFilledInput-underline {
    &:before,
    &:after {
      display: none;
    }
  }
}

@include breakpoint(sm) {
  .tag-range-table {
    overflow: auto;

    .custom-data-grid {
      width: 1180px;
    }

    .filter-btn {
      left: 1110px;
      right: inherit;
    }
  }
}

.qty-field,
.department-field,
.price-field {
  width: calc(100% + 40px);
  // cursor: pointer;
  height: calc(58px + 6px);
  margin: -12px -18px !important;
  display: flex !important;
  span {
    display: flex;
    align-items: center;
    padding-right: 10px;
    width: 100%;
    height: 100%;
    justify-content: right;
  }
  input {
    font-family: "Open Sans", sans-serif;
  }
  @include breakpoint(sm) {
    width: calc(100% + 20px);
    height: calc(50px + 6px);
    margin: -12px -4px !important;
  }
}

.qty-input {
  justify-content: center;
  text-align: right;
  width: calc(100% + 40px);
  cursor: pointer;
  background: rgba($secondary-color-4, 0.05);
  height: calc(58px + 6px);
  margin: -12px -18px;
  display: flex;
  span {
    display: flex;
    align-items: center;
    padding-right: 20px;
    width: 100%;
    height: 100%;
    justify-content: right;
  }

  .MuiFilledInput-underline {
    background: none;
  }

  .MuiInputBase-formControl {
    // background: none;
    &:before {
      display: none;
    }

    input {
      padding: 4px;
      text-align: right;
    }

    &.Mui-focused {
      background: whitesmoke;

      input {
        text-align: left;
      }
    }
  }
}
.qty-input-no-bg {
  justify-content: center;
  text-align: right;
  width: calc(100% + 40px);
  height: calc(58px + 6px);
  margin: -12px -18px;
  display: flex;
  span {
    display: flex;
    align-items: center;
    padding-right: 20px;
    width: 100%;
    height: 100%;
    justify-content: right;
  }

  .MuiFilledInput-underline {
    background: none;
  }

  .MuiInputBase-formControl {
    // background: none;
    &:before {
      display: none;
    }

    input {
      padding: 4px;
      text-align: right;
    }

    &.Mui-focused {
      background: whitesmoke;

      input {
        text-align: left;
      }
    }
  }
}
.qty-bg {
  background: $secondary-color-3;
  margin: 0;
  cursor: pointer;
  position: relative;
  text-align: right;
  font-weight: 600;
  padding: 10px;
  display: flex;
  justify-content: right;

  //max-width:90px;
  .MuiInputBase-formControl {
    background: $secondary-color-3;

    &:before {
      display: none;
    }

    input {
      padding: 4px;
      text-align: right;
      font-weight: 600;
    }

    &.Mui-focused {
      background: whitesmoke;

      input {
        text-align: right;
      }
    }
  }
}

.qty-bg1 {
  background: #b7c1ec;
  margin: 0;
  text-align: right;
  font-weight: 600;
  padding: 10px;
  display: flex;
  justify-content: right;
  cursor: pointer;
  //max-width:90px;
  .MuiInputBase-formControl {
    background: #b7c1ec;

    &:before {
      display: none;
    }

    input {
      padding: 4px;
      text-align: right;
      font-weight: 600;
    }

    &.Mui-focused {
      background: whitesmoke;

      input {
        text-align: right;
      }
    }
  }
}

.qty-error {
  position: relative;
  margin: 0;
  cursor: pointer;
  //max-width:90px;
  .MuiInputBase-formControl {
    &:before {
      display: none;
    }

    input {
      background: rgba($danger-color-1, 0.1);
      color: $danger-color-1;
      padding: 4px;
    }

    &.Mui-focused {
      background: whitesmoke;

      input {
        text-align: right;
      }
    }
  }

  .MuiFilledInput-underline {
    &:after {
      border-color: $danger-color-1;
    }
  }
}

.qty-warn {
  position: relative;
  margin: 0;
  cursor: pointer;
  //max-width:90px;
  .MuiInputBase-formControl {
    &:before {
      display: none;
    }

    input {
      background: rgba(255, 132, 0, 0.2);
      color: #ff8400;
      padding: 4px;
    }

    &.Mui-focused {
      background: whitesmoke;

      input {
        text-align: right;
      }
    }
  }

  .MuiFilledInput-underline {
    &:after {
      border-color: #ff8400;
    }
  }
}
.editField {
  input {
    width: 80px;
    text-align: right;
    @include breakpoint(sm) {
      padding-top: 10px;
      width: 60px;
    }
  }
}
[class*="TagDetails_highlighter"] {
  display: inline-block;
}

.accuracy-status {
  font-weight: 700;
  display: flex;
  align-items: center;

  svg {
    margin-right: 5px;
  }

  &.success {
    color: $primary-color-2;
  }

  &.warning {
    color: #ff8400;
  }

  &.error {
    color: $danger-color-1;
  }
}

.filter-selected,
.sort-selected {
  position: relative;

  &:before {
    content: "";
    width: 10px;
    height: 10px;
    background: $primary-color-1;
    position: absolute;
    right: 3px;
    top: 3px;
    border-radius: 50px;
  }
}

.suppressed {
  opacity: 0.25 !important;
}

.sort-icon-color {
  color: $primary-color-4;
}

.text-box {
  position: relative;

  .validate-msg {
    left: 0;
  }
}

.upper-case {
  text-transform: uppercase;
}

.new-count-head {
  @include breakpoint(sm) {
    white-space: nowrap;

    button {
      margin: 0 0 0 10px !important;

      img,
      svg {
        margin-right: 4px !important;
      }
    }
  }
}

.table-fixed {
  // ensures that the table respects the widths defined on the <th> elements.
  table-layout: fixed; /* Fixes the table layout */

  .table-ellipsis {
    display: inline-block !important; /* Allows ellipsis to apply */
    max-width: 100%; /* Ensures it fits within the parent */
    overflow: hidden; /* Hide overflow */
    white-space: nowrap; /* Prevent text wrapping */
    text-overflow: ellipsis; /* Show ellipsis */
  }
}



.report-header {
  text-align: right;

  &--sort {
    padding-right: 30px;

    &.report-header--filter {
      padding-right: 54px;
    }
  }
  .custom-table__sort {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    .report-header--sort {
      padding-right: 0;
    }
    span {
      position: inherit;
    }
  }
}

.sticky-header {
  .custom-table {
    thead {
      position: sticky;

      top: 0;

      z-index: 1;

      th {
        border-top: none !important;

        box-shadow: inset 0 1px 0 rgba(0, 0, 0, 0.08),
          inset 0 0px 0 rgba(0, 0, 0, 0.08);
      }
    }
  }
}
.min-header {
  background: #043263;
  padding: 24px 60px;
  h6 {
    font-size: 18px;
    color: white;
    line-height: 26px;
    text-transform: uppercase;
    margin: 0;
    padding: 0;
    font-weight: 700;
  }
}
.survey-content {
  h2 {
    margin: 0 0 24px 0;
    padding: 40px 0 16px 0;
    color: #0F0F0F;
    font-weight: 700;
    font-size: 24px;
    text-transform: uppercase;
    line-height: 34px;
    border-bottom: 1px solid #CECECE;
  }
}
.survey-column {
  &__row {
    background: whitesmoke;
    margin-bottom: 16px;
    display: flex;
    justify-content: space-between;
    padding: 10px 160px 10px 10px;
    align-items: center;
  }
  &__title {
    display: inline-flex;
    align-items: center;
    min-width: 250px;
    span {
      background: #043263;
      font-size: 12px;
      color: white;
      width: 28px;
      height: 28px;
      font-weight: 600;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      border-radius: 50px;
      margin-right: 24px;
    }
    label {
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
    }
  }
}
.custom-rating {
  display: inline-flex;
  align-items: center;
  min-width: 320px;
  svg {
    font-size: 26px;
    margin-right: 10px;
    color: #F1C644;
  }
  .MuiRating-iconEmpty {
    svg {
      color: #D4D4D4;
    }
  }
  &__label{
    font-size:14px;
    font-weight: 600;
    margin-top:4px;
  }
}
.custom-checkbox {
  border: 1px solid #727272;
  border-radius: 0;
  width: 24px;
  height: 24px;
  margin-right: 16px;
  svg {
    display: none;
  }
  &.Mui-checked {
    svg {
      display: inline-block;
      font-size: 16px;
    }
  }
  &.light {
    background-color: white;
  }
}
.counter-flag{
  display: inline-flex;
  align-items: center;
  min-width: 100px;
  .counter-img{
      height: 24px;
      margin-left: 8px;
  }
}

.table-responsive {
  overflow-x: initial;
}
