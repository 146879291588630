@import "./colors";
@import "./responsive";

::-webkit-scrollbar {
  width: 8px;
  height: 5px;
}

::-webkit-scrollbar-track {
  background: $secondary-color-1;
  border-radius: 6px;
}

::-webkit-scrollbar-thumb {
  background: rgba($secondary-color-2, 0.7);
  border-radius: 6px;
}

::-webkit-scrollbar-thumb:hover {
  background: rgba($secondary-color-2, 0.9);
}

.green-text {
  color: $primary-color-2 !important;
}

.black-text {
  color: $body-text !important;
}

.red-text {
  color: $danger-color-1 !important;
}

.warning-text {
  color: $warning-color-1 !important;
}

.white-text {
  color: $white !important;
}

.blue-text {
  color: $primary-color-1 !important;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.custom-breadcrumb {
  li {
    font-family: "Open Sans", sans-serif;
    color: #727272;

    p {
      font-family: inherit;
      font-size: 36px;
      line-height: 44px;
      font-weight: 300;
      color: $primary-color-4;
      text-transform: capitalize;
    }

    .input-block {
      color: $primary-color-4;
      font-size: 32px;
      line-height: 44px;
      font-weight: 300;
      position: relative;

      input {
        border: none;
        border-bottom: 1px dashed #666;
        width: 190px;
        padding: 0;
        margin: 0;
        color: $primary-color-4;
        font-weight: 300;

        &:focus {
          border: none;
          border-bottom: 1px dashed $primary-color-4;
          outline: none;
          background: $secondary-color-1;
        }
      }

      .validate-msg {
        position: absolute;
        left: 66px;
        line-height: normal;
        bottom: -18px;
        white-space: nowrap;
      }
    }
  }

  &__link {
    font-size: 26px;
    line-height: 36px;
    font-weight: 300;
    position: relative;
    text-transform: capitalize;
    color: #727272;

    &:hover {
      text-decoration: none;
      color: $primary-color-4;
    }

    @include breakpoint(vs) {
      max-width: 120px;
      overflow: hidden;
      display: inline-flex;
      white-space: nowrap;
    }
  }

  .MuiBreadcrumbs-separator {
    font-size: 26px;
    line-height: 44px;
    font-weight: 300;

    @include breakpoint(sm) {
      font-size: 18px;
      line-height: 26px;
      margin: 0 6px;
    }
  }

  @include breakpoint(sm) {
    &__link {
      font-size: 18px;
      line-height: 26px;
    }

    li {
      .input-block,
      p {
        font-size: 24px;
        line-height: 34px;

        input {
          width: 130px;
        }
      }
    }
  }

  @include breakpoint(vs) {
    &__link,
    .MuiBreadcrumbs-separator {
      font-size: 16px;
    }

    li {
      .input-block,
      p {
        font-size: 18px;
      }

      .input-block input {
        width: 40px;
      }
    }
  }
}

.validate-msg {
  color: $danger-color-1;
  font-size: 12px;
  position: absolute;
  bottom: -20px;
}
.validate-instancemsg {
  color: $danger-color-1;
  font-size: 12px;
  bottom: -20px;
  text-transform: initial;
}
.page-not-found {
  text-align: center;
  padding: 104px 0;

  h2 {
    font-size: 42px;
    line-height: 58px;
    font-weight: 300;
    margin-top: 24px;

    span {
      display: block;
    }
  }

  p {
    font-size: 24px;
    line-height: 34px;
    color: #666;
    margin-top: 24px;
    font-weight: 300;
    margin-bottom: 0;

    span {
      display: block;
    }
  }

  a {
    text-decoration: none;
    background: #1464a5;
    padding: 13px 36px;
    font-size: 16px;
    line-height: 22px;
    font-weight: 700;
    color: white;
    text-transform: uppercase;
    margin-top: 40px;
    display: inline-block;

    &:hover {
      color: white;
      opacity: 0.9;
    }
  }

  &-page {
    footer {
      display: none !important;
    }

    main {
      height: 100vh;
      overflow: hidden;
    }

    .main-wrapper {
      margin-top: 60px !important;
      height: calc(100vh - 60px);
      min-height: calc(100vh - 60px);
      max-width: 100%;
      padding-right: 0;
    }
  }
}

.validate-msg-qty-error {
  color: #212121;
  font-size: 11px;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 100%;
  overflow: hidden;
  display: inline-block;
  position: relative;
  top: 4px;
  cursor: default;

  svg {
    height: 16px;
    color: red;
    vertical-align: sub;
  }
}

.validate-msg-qty-warn {
  color: #212121;
  font-size: 11px;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 100%;
  overflow: hidden;
  display: inline-block;
  position: relative;
  top: 4px;
  cursor: default;

  svg {
    height: 16px;
    color: $warning-color-1;
    vertical-align: sub;
  }
}

.MuiTooltip-tooltip {
  font-family: inherit;
  font-size: 14px;
  line-height: 22px;
  padding: 8px 12px;
  border-radius: 0;
  background: $white;
  color: $body-text;
  font-weight: 400;
  filter: drop-shadow(0px 0px 2px rgba(145, 158, 171, 0.5))
    drop-shadow(0px 12px 24px rgba(145, 158, 171, 0.5));
}

.mw-70 {
  min-width: 70px;
}

.MuiTooltip-arrow {
  color: $white;
}

.prompts-container {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  max-height: 300px;
  width: calc(50% - 16px);
  margin: -10px 0 -50px 0;

  &.more-than-4 {
    width: calc(100% - 16px);
  }

  &.more-than-8 {
    width: calc(33% - 16px);
  }

  .text-box {
    margin: 8px;

    .MuiFormControl-root {
      min-width: 100%;
    }
  }
}

.select-grip {
  position: relative;

  input {
    opacity: 1;
    border: none;
    position: absolute;
    top: 19px;
    padding: 0 13px;
    margin: 0;
    left: 0;
    background: transparent;
    font-size: 15px;
  }

  .MuiSelect-filled {
    opacity: 0;
  }
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-default {
  cursor: default !important;
}

// input autocomplete background color removed styles
input:-webkit-autofill {
  background-color: #f0f0f0 !important;
  -webkit-box-shadow: 0 0 0 50px #f0f0f0 inset;
}

.Mui-error {
  input:-webkit-autofill {
    background-color: #fff0f0 !important;
    -webkit-box-shadow: 0 0 0 50px #fff0f0 inset;
  }
}

.highlighter {
  .highlighted {
    color: black;
    background-color: yellow;
  }

  .active_highlighted {
    background-color: orange;
  }
}

.highlighted {
  color: black;
  background-color: yellow;
}

.active_highlighted {
  background-color: orange;
}

.upload-file {
  background: white;
  border: 1px dashed rgba($secondary-color-2, 0.5);
  height: 144px;
  //width: 440px;
  text-align: center;
  position: relative;

  p {
    display: block;
    margin-bottom: 0;
    font-size: 15px;
    line-height: 20px;
    margin-top: 8px;

    .primary-btn {
      font-size: 15px;
      line-height: 1;
      font-weight: 600;
    }
  }

  img {
    max-height: 60px;
    display: none;
    margin-top: 24px;
    max-width: 100%;
    margin-bottom: 8px;

    &[src] {
      display: inline-block;

      & + svg {
        display: none;
      }
    }
  }

  &__icon {
    font-size: 40px;
    color: #666666;
    margin-top: 40px;
  }

  &__actions {
    background: #666;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1;
    width: 100%;
    display: none;
    height: 100%;
    justify-content: center;
    align-items: center;
    color: white;
    flex-direction: column;

    svg {
      cursor: pointer;
    }

    &--row {
      width: 100%;

      span {
        display: block;
        font-size: 12px;
        font-weight: 600;
        text-transform: uppercase;
        margin-bottom: 8px;
      }

      svg {
        font-size: 18px;
      }
    }
  }

  &:hover {
    .upload-file__actions {
      display: flex;
    }
  }
}

hr {
  &.dashed {
    border: 1px dashed #a1a1a1;
  }

  &.custom-border {
    border: 1px solid #cecece;
  }
}

.filter-panel {
  position: absolute;
  left: 0;
  top: 104px;
  width: 100%;
  background: transparent;
  z-index: 8;

  .gray-bg {
    box-shadow: -1px 5px 1px rgba(0, 0, 0, 0.15);

    h4 {
      font-weight: 700;
      text-transform: uppercase;
      font-size: 24px;
      line-height: 34px;
      margin-bottom: 16px;
    }

    .inner-view__footer {
      border-top: 1px solid #d3d3d3;
      padding-top: 24px;
      margin-top: 32px;
      align-items: center;
    }

    .MuiPaper-elevation,
    .MuiPaper-elevation ul {
      max-height: 208px !important;
    }
  }
}

.filter-icon {
  position: relative;
  z-index: 10;

  &__count {
    position: absolute;
    top: -10px;
    right: -8px;
    background: $primary-color-3;
    width: 20px;
    height: 20px;
    border-radius: 50px;
    font-size: 10px;
    line-height: 20px;
    color: #fff;
    font-weight: 700;
  }
}

.align-top-table {
  .custom-table td {
    vertical-align: top;

    .line-through {
      text-decoration: line-through;
      color: #a1a1a1;
      margin-top: 6px;
      display: inline-block;

      &.edit-source {
        text-decoration: none;
      }
    }
  }
}

.align-middle {
  display: flex;
  align-items: center;
  .align-right {
    margin-left: auto;
  }
}

.inner-view {
  margin: 120px 0 0;
  &__title {
    border-bottom: 1px solid #bdbdbd;
    padding-bottom: 24px;
    margin-bottom: 32px;
    font-weight: 700;
    text-transform: uppercase;
  }
  &__footer {
    border-top: 1px solid #bdbdbd;
    margin: 120px 0;
    text-align: right;
    button {
      margin-top: 40px;
    }
  }
}
.data-mapping {
  &-block {
    border-top: 1px dashed #bdbdbd;
    margin-top: 24px;
    padding-top: 16px;
    h4 {
      font-size: 14px;
      font-weight: 700;
      margin: 8px 0 16px 0;
      text-transform: uppercase;
    }
  }
  &__row {
    display: flex;
    align-items: center;
    margin-bottom: 12px;
    label {
      width: 150px;
      font-size: 14px;
    }
    svg {
      font-size: 14px;
    }
    .custom-select {
      width: 200px;
      .MuiFilledInput-root {
        height: auto;
      }
      &:before {
        padding: 3px;
        top: 18px;
      }
    }
  }
  .sign-block {
    .MuiFormControl-root {
      width: 100%;
    }

    canvas {
      height: 250px;
    }
  }
}
.fit-content {
  // min-width: fit-content;
}
.text-uppercase {
  text-transform: uppercase;
}
// .web-chat-box {
//   position: fixed; /* Fix the position so it stays in the same spot */
//   bottom: 0; /* Align to the bottom of the page */
//   right: 0; /* Align to the right of the page */
//   width: 22%; /* Set the width of the container */
//   height: calc(100% - 150px);
//   background-color: white; /* Set background color */
//   z-index: 1000; /* Make sure it appears on top of other elements */
//   box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
// }
// #webchatbanner {
//   align-items: center;
//   background-color: black;
//   display: flex;
//   height: 50px;
//   display: none;
//   padding: 0 20px;
// }
// #webchat {
//   height: calc(100% - 250px);
//   overflow: hidden;
//   position: fixed;
//   bottom: 5px;
// }

// .chatbot-title {
//   color: whitesmoke;
//   font-family: Segoe UI;
//   font-size: 25px;
//   line-height: 45px;
//   margin: 0;
//   padding-left: 10px;
// }
.repack-label {
  text-transform: uppercase;
  color: #fd7a49;
  margin-left: 10px;
  font-weight: bold;
}
.text-transform-none{
  text-transform: none;
}
.mt-16{
  margin-top: 16px;
}
.p-l-15{
  padding-left: 15px !important;
}
